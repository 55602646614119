<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="close" />
        Oligoelemento: {{ selectedOligoelement.name }}
      </v-card-title>
      <v-card-text>
        <property-value property="Tipo" :value="selectedType.name" />
        <property-value
          v-if="selectedOligoelement.explanation"
          property="Explicação ao paciente"
          :value="selectedOligoelement.explanation"
        />
        <property-value
          property="Informações"
          :value="selectedOligoelement.information"
        />
        <property-value
          v-show="!!selectedOligoelement.dailyIntake"
          property="Ingestão diária recomendada"
          :value="selectedOligoelement.dailyIntake"
        />
        <div>
          <span
            class="body-2 font-weight-bold grey--text text--darken-3 black--text"
          >
            Recomendar fontes alimentares
          </span>
          <v-list>
            <v-list-item-group
              v-model="selectedSources"
              multiple
              color="primary"
            >
              <v-list-item
                v-for="(source, index) in selectedOligoelement.sources"
                :key="source.name + index"
                class="list-item"
                :value="source"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-icon>
                    <v-icon>
                      {{ active ? "check_circle" : "panorama_fish_eye" }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ source.name }}
                  </v-list-item-title>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <v-card flat class="text-center">
          <v-row
            v-show="showNewSoruce"
            align="baseline"
            justify="center"
            no-gutters
          >
            <v-col sm="8" cols="12" class="mr-sm-3">
              <v-text-field
                v-model.trim="newSource"
                :autofocus="showNewSoruce"
                label="Nome"
                outlined
              />
            </v-col>
            <v-col sm="2" cols="12">
              <v-btn large color="secondary" @click="addSource">
                <v-icon class="mr-2">add_circle</v-icon>
                Adicionar fonte
              </v-btn>
            </v-col>
          </v-row>
          <v-btn
            large
            outlined
            class="blue--text mt-5"
            text
            @click="toggleNewSource"
          >
            Fonte alimentar não listada?
          </v-btn>
          <v-divider class="mt-5" />
          <v-switch
            v-model="hidePatient"
            :value="hidePatient"
            label="Esconder ao paciente na diagnose"
          />
          <v-switch
            v-model="patientImpactAtHome"
            :value="patientImpactAtHome"
            label="Recomendar ao paciente impactar em casa"
          />
          <comments v-model="comments" />
        </v-card>
      </v-card-text>
      <div class="mt-5 d-flex flex-column">
        <v-btn color="secondary" x-large @click="addToDiagnose">
          Adicionar ao Diagnóstico
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IndexDialog",
  components: {
    comments: () =>
      import("@/domains/therapy/shared/presentation/components/Comments"),
  },
  data: () => ({
    selectedSources: [],
    newSource: "",
    showNewSoruce: false,
    comments: {},
    hidePatient: false,
    patientImpactAtHome: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.oligoelements.specific;
      },
      set(value) {
        this.$store.commit("dialogs/setOligoelementsSpecific", value);
      },
    },
    selectedOligoelement() {
      return this.$store.state.oligoelements.selectedOligoelement;
    },
    selectedType() {
      return this.$store.state.oligoelements.selectedType;
    },
  },
  methods: {
    close() {
      this.clearState();
      this.dialog = false;
    },
    addSource() {
      if (!this.newSource) return;
      this.$store.commit("oligoelements/addSourceToSelectedOligoelement", {
        name: this.newSource,
      });
      this.newSource = "";
    },
    toggleNewSource() {
      this.showNewSoruce = !this.showNewSoruce;
    },
    validate() {
      const exists = this.$store.state.diagnosis.diagnose.oligoelements.some(
        (diagnoseOligoelement) =>
          diagnoseOligoelement.oligoelement.id === this.selectedOligoelement.id
      );

      if (exists) {
        this.$toasted.global.error({
          message: "Oligoelemento já adicionado ao diagnóstico",
        });
        return false;
      }

      return true;
    },
    async addToDiagnose() {
      if (!this.validate()) return;

      const { therapistComments, patientComments } = this.comments;

      const completeDiagnoseOligoelement = {
        sources: this.selectedSources,
        therapistComments,
        patientComments,
        oligoelement: {
          ...this.selectedOligoelement,
          type: {
            name: this.selectedType.name,
          },
        },
        hidePatient: this.hidePatient,
        patientImpactAtHome: this.patientImpactAtHome,
      };
      this.$store.commit(
        "diagnosis/addOligoelement",
        completeDiagnoseOligoelement
      );
      this.clearState();
      this.$toasted.global.success({
        message: "Oligoemento adicionado ao diagnóstico",
      });
      this.dialog = false;
    },
    clearState() {
      this.sources = [];
      this.newSource = "";
      this.showNewSoruce = false;
    },
  },
};
</script>

<style scoped></style>
